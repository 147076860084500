
import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'
import Photo from './PhotoEastbay'

const Eastbay = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    const idTimeOut = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 4000)

    return () => clearTimeout(idTimeOut);
  }, [])

  return (
    <>
      <div className="container about-page">
        <div className="eastbay-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['CSU',' ','East',' ', 'Bay']}
              idx={5}
            />
          </h1>
          <p align="LEFT">
          • Established session and cookie management utilities on top of Flask and SocketIO.
          </p>
          <p align="LEFT">
          • Developed session timeout users. Deployed the web service with a docker container in AWS EC2.
          </p>
          <p align="LEFT">
          • Migrated a chatbot from Dialogflow with a limitation of 2,000 intents to an in-house chatbot system by integrating with the
Blackboard. Shifting towards Canvas.
          </p>
          <p align="LEFT">
          • Deployed the web service with docker container in AWS EC2 as a Production Environment and Local as Development.
          </p>
          <p align="LEFT">
          • Developed a Machine Learning Model with 20% faster response time and 79% Accuracy of the responses for Queries.
          </p>
          <p align="LEFT">
          • Designed and developed interface for University Faculty file managment system including Authentication as well as
Authorization using ReactJs, Javascript, HTML & CSS.
          </p>
          <p align="LEFT">
          • Graduate Teaching Associate - Course : CS 201 Computer Science II (Programming in C++)
          </p>
          <p align="LEFT">
          • Strong knowledge of C++ algorithms and data structures for problem-solving. Detail-oriented approach to reviewing and
grading C++ assignments, assessing code quality, style, and efficiency.
          </p>
          <p align="LEFT">
          • Configuring Report generation and SQL Query. Design through Oracle Business Intelligence reporting suite.
          </p>
        </div>
        <Photo />
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default Eastbay

import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'
import Photo from './PhotoPeearz'

const Peearz = () => {
    const [letterClass, setLetterClass] = useState('text-animate')

    useEffect(() => {
        const idTimeOut = setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 4000)

        return () => clearTimeout(idTimeOut);
    }, [])

    return (
        <>
            <div className="container about-page">
                <div className="peearz-zone">
                    <h1>
                        <AnimatedLetters
                            letterClass={letterClass}
                            strArray={['Peearz', ' ', 'Health']}
                            idx={3}
                        />
                    </h1>
                    <p align="LEFT">
                        • Architecting Scalable Backend Systems: Designed and implemented highly scalable backend architectures, utilizing cloud-native technologies to support seamless health data management and real-time patient engagement across multiple platforms.
                    </p>
                    <p align="LEFT">
                        • AI-Driven Clinical Trial Matching: Developed and deployed sophisticated AI algorithms tailored to optimize clinical trial matching, enhancing patient outcomes through personalized trial recommendations.
                    </p>
                    <p align="LEFT">
                        • Data Privacy & Security Compliance: Led initiatives to ensure strict adherence to HIPAA and GDPR standards, incorporating advanced encryption techniques and secure access controls to protect sensitive patient data.
                    </p>
                    <p align="LEFT">
                        • Multilingual AI Chatbot Integration: Spearheaded the development of AI-native chatbots with multilingual capabilities, leveraging NLP techniques to provide real-time, context-aware support to users worldwide.
                    </p>
                    <p align="LEFT">
                        • Mobile App Development: Engineered intuitive mobile applications for both Android and iOS platforms, focused on enhancing user interaction with health records and facilitating smooth data exchange between users and healthcare providers.
                    </p>
                    <p align="LEFT">
                        • Conducted thorough testing and debugging procedures, ensuring seamless integration of TypeScript, Next.js, and React.js components with the OpenAI API, and validating prompt engineering strategies to deliver high-quality software solutions.
                    </p>
                    <p align="LEFT">
                        • Data Insights and Search Optimization: Leveraged advanced SQL Langchain agents to derive actionable insights from user databases, focusing on historical data and asset management records to inform decision-making. Additionally, developed a Retrieval-Augmented Generation (RAG) based search engine to efficiently parse and analyze company forum pages, utilizing vector embeddings for similarity search.
                    </p>
                </div>
                <Photo />
            </div>
            <Loader type="pacman" />
        </>
    )
}

export default Peearz
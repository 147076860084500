import './index.scss'
import { Link } from 'react-router-dom';
import AnimatedLetters from '../AnimatedLetters'
import { useEffect, useState } from 'react'
import { useRef } from 'react'
import AM from "../../assets/images/amotions.png"
import PH from "../../assets/images/peearz.png"
import EB from "../../assets/images/east-bay-sign.png"
import PU from "../../assets/images/sppu.png"
import BH from "../../assets/images/berkshire.png"
import BT from "../../assets/images/brown.png"


const Portfolio = () => {

    const [letterClass, setLetterClass] = useState('text-animate')
    const form = useRef()

    useEffect(() => {
        const idTimeOut = setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
        return () => clearTimeout(idTimeOut);
    }, [])


    return (
        <>
            <div className="container portfolio-page">
                <div className="port-zone">
                    <h1>
                        <AnimatedLetters
                            letterClass={letterClass}
                            strArray={['Education']}
                            idx={1}
                        />
                    </h1>
                    <div className='dabba'>
                        <div className='card'>
                            <a href='https://www.csueastbay.edu/' target="_blank" >
                                <img src={EB} alt='EB' />
                                <div className='card-dropdown' >
                                    <div className='card-heading'><h2>CAL STATE EAST BAY</h2></div>
                                    <div className='card-body'><h3>MSc. Computer Science</h3></div>
                                </div>
                            </a>
                        </div>
                        <div className='card'>
                            <a href='http://www.unipune.ac.in/' target="_blank" >
                                <img src={PU} alt='EB' />
                                <div className='card-dropdown' >
                                    <div className='card-heading'><h2>UNIVERSITY OF PUNE</h2></div>
                                    <div className='card-body'><h3>BEngg. Computer Science</h3></div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <h1>
                        <AnimatedLetters
                            letterClass={letterClass}
                            strArray={['Experience']}
                            idx={1}
                        />
                    </h1>
                    <div className='dabba'>
                        <div className='card'>
                            <Link to="/peearz" className="flat-button">
                                <img src={PH} alt='PH' />
                                <div className='card-dropdown' >
                                    <div className='card-heading'><h2>Peearz Health</h2></div>
                                    <div className='card-body'><h3>Founding Software Engineer</h3></div>
                                </div>
                            </Link>
                        </div>
                        <div className='card'>
                            <Link to="/amotions" className="flat-button">
                                <img src={AM} alt='AM' />
                                <div className='card-dropdown' >
                                    <div className='card-heading'><h2>Amotions Inc.</h2></div>
                                    <div className='card-body'><h3>Software Engineer</h3></div>
                                </div>
                            </Link>
                        </div>
                        <div className='card'>
                            <Link to="/berkshire" className="flat-button">
                                <img src={BH} alt='BH' />
                                <div className='card-dropdown' >
                                    <div className='card-heading'><h2>Berkshire Hathaway</h2></div>
                                    <div className='card-body'><h3>Full Stack Developer</h3></div>
                                </div>
                            </Link>
                        </div>
                        <div className='card'>
                            <Link to="/eastbay" className="flat-button">
                                <img src={EB} alt='EB' />
                                <div className='card-dropdown' >
                                    <div className='card-heading'><h2>CAL STATE EAST BAY</h2></div>
                                    <div className='card-body'><h3>Graduate Assistant</h3></div>
                                </div>
                            </Link>
                        </div>
                        <div className='card'>
                            <Link to="/BrownFrame" className="flat-button">
                                <img src={BT} alt='BT' />
                                <div className='card-dropdown' >
                                    <div className='card-heading'><h2>BrownFrame Technologies</h2></div>
                                    <div className='card-body'><h3>Software Engineer</h3></div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Portfolio




import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'
import Photo from './PhotoAmotions'

const Berkshire = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    const idTimeOut = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 4000)

    return () => clearTimeout(idTimeOut);
  }, [])

  return (
    <>
      <div className="container about-page">
        <div className="amotions-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['Amotions', ' ', 'Inc.']}
              idx={3}
            />
          </h1>
          <p align="LEFT">
            • Implemented robust front-end solutions using TypeScript, Next.js, and React.js, enhancing user experience and ensuring code maintainability and scalability.
          </p>
          <p align="LEFT">
            • Integrated cutting-edge AI capabilities by leveraging the OpenAI API, contributing to the development of intelligent applications with natural language processing and machine learning functionalities.
          </p>
          <p align="LEFT">
            • Utilized Supabase as a backend-as-a-service, leveraging its tools such as real-time databases, authentication services, and instant APIs to enhance application development.
          </p>
          <p align="LEFT">
            • Spearheaded prompt engineering initiatives, refining input prompts for AI models and optimizing response generation algorithms to enhance the quality and relevance of AI-generated content.
          </p>
          <p align="LEFT">
            • Collaborated closely with cross-functional teams to architect and develop innovative features, leveraging TypeScript and Next.js to build responsive and performant web applications that meet business objectives.
          </p>
          <p align="LEFT">
            • Conducted thorough testing and debugging procedures, ensuring seamless integration of TypeScript, Next.js, and React.js components with the OpenAI API, and validating prompt engineering strategies to deliver high-quality software solutions.
          </p>
          <p align="LEFT">
            • Deployed applications on Netlify, utilizing its continuous deployment capabilities from Git repositories to streamline updates and manage production and development environments efficiently.
          </p>
        </div>
        <Photo />
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default Berkshire

import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'
import Photo from './PhotoBerkshire'

const Berkshire = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    const idTimeOut = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 4000)

    return () => clearTimeout(idTimeOut);
  }, [])

  return (
    <>
      <div className="container about-page">
        <div className="berkshire-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['Berkshire', ' ', 'Hathaway']}
              idx={3}
            />
          </h1>
             <p align="LEFT">
            • Developed and tested an infotainment module using Agile methodologies, ensuring efficient and reliable software delivery.
            </p>
            <p align="LEFT">
            • Configured Spring Boot applications and implemented Spring Security for secure authentication and authorization processes.
            </p>
            <p align="LEFT">
            • Designed and implemented user interfaces (UI) with HTML, CSS, Bootstrap, JavaScript, and React.js, ensuring adherence to
accessibility and web browser standards.
            </p>
            <p align="LEFT">
            • Created web interfaces and implemented business logic using Spring, JSP, Servlets, JavaBeans, JDBC, and XML technologies.
            </p>
            <p align="LEFT">
            • Utilized Node.js as a proxy to facilitate seamless interaction with RESTful services and efficient database communication.
            </p>
            <p align="LEFT">
            • Implemented AWS Security Groups as virtual firewalls to control traffic access to AWS EC2 instances, ensuring robust security.
            </p>
            <p align="LEFT">
            • Developed, and deployed J2EE applications on Apache Tomcat application servers, ensuring scalability and performance.
            </p>
            <p align="LEFT">
            • Implemented CRUD operations on MySQL databases for various administrative system-related tables and product components,
improving data management and system functionality.
            </p>
        </div>
        <Photo />
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default Berkshire
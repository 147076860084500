import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { useRef } from 'react'
import emailjs from '@emailjs/browser'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'

const Contact = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const form = useRef()

  useEffect(() => {
    const idTimeOut = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
    return () => clearTimeout(idTimeOut);
  }, [])

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs
      .sendForm('service_kuu4bi8', 'template_67d255i', form.current, 'I-YDRTW2o1cEe_0g8')
      .then(
        () => {
          alert('Message successfully sent!')
          window.location.reload(false)
        },
        () => {
          alert('Failed to send the message, please try again')
        }
      )
  }

  return (
    <>
      <div className="container contact-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['Contact', ' ', 'me']}
              idx={3}
            />
          </h1>
          <p>
            I am interested in freelance opportunities - especially on ambitious
            or large projects. However, if you have any other requests or
            questions, don't hesitate to contact me using below form either.
          </p>
          <div className="contact-form">
            <form ref={form} onSubmit={sendEmail}>
              <ul>
                <li className="half">
                  <input placeholder="Name" type="text" name="name" required />
                </li>
                <li className="half">
                  <input
                    placeholder="Email"
                    type="email"
                    name="email"
                    required
                  />
                </li>
                <li>
                  <input
                    placeholder="Subject"
                    type="text"
                    name="subject"
                    required
                  />
                </li>
                <li>
                  <textarea
                    placeholder="Message"
                    name="message"
                    required
                  ></textarea>
                </li>
                <li>
                  <input type="submit" className="flat-button" value="SEND" />
                </li>
              </ul>
            </form>
          </div>
        </div>
        {/* <div className="info-map">
          Slobodan Gajić,
          <br />
          Serbia,
          <br />
          Branka RadiČevića 19, 22000 <br />
          Sremska Mitrovica <br />
          <br />
          <span>freelancerslobodan@gmail.com</span>
        </div>
        <div className="map-wrap">
          <MapContainer center={[44.96366, 19.61045]} zoom={13}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={[44.96366, 19.61045]}>
              <Popup>Sloba lives here, come over for a cup of coffee :)</Popup>
            </Marker>
          </MapContainer>
        </div> */}
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default Contact


// import { useEffect, useState, useRef } from 'react'
// import Loader from 'react-loaders'
// import AnimatedLetters from '../AnimatedLetters'
// import './index.scss'
// import emailjs from '@emailjs/browser'
// import SnakeGame from './SnakeGame';  // Correct the path if needed

// const Contact = () => {
//   const [letterClass, setLetterClass] = useState('text-animate')
//   const form = useRef()

//   useEffect(() => {
//     const idTimeOut = setTimeout(() => {
//       setLetterClass('text-animate-hover')
//     }, 3000)
//     return () => clearTimeout(idTimeOut);
//   }, [])

//   const sendEmail = (e) => {
//     e.preventDefault()

//     emailjs
//       .sendForm('service_kuu4bi8', 'template_67d255i', form.current, 'I-YDRTW2o1cEe_0g8')
//       .then(
//         () => {
//           alert('Message successfully sent!')
//           window.location.reload(false)
//         },
//         () => {
//           alert('Failed to send the message, please try again')
//         }
//       )
//   }

//   return (
//     <>
//       <div className="container contact-page">
//         <div className='left'>
//           <div className="text-zone">
//             <h1>
//               <AnimatedLetters
//                 letterClass={letterClass}
//                 strArray={['Contact', ' ', 'me']}
//                 idx={15}
//               />
//             </h1>
//             <p>
//               I am interested in freelance opportunities - especially ambitious
//               or large projects. However, if you have any other requests or
//               questions, don't hesitate to contact me using the form below.
//             </p>
//             <div className="contact-form">
//               <form ref={form} onSubmit={sendEmail}>
//                 <ul>
//                   <li className="half">
//                     <input placeholder="Name" type="text" name="name" required />
//                   </li>
//                   <li className="half">
//                     <input
//                       placeholder="Email"
//                       type="email"
//                       name="email"
//                       required
//                     />
//                   </li>
//                   <li>
//                     <input
//                       placeholder="Subject"
//                       type="text"
//                       name="subject"
//                       required
//                     />
//                   </li>
//                   <li>
//                     <textarea
//                       placeholder="Message"
//                       name="message"
//                       required
//                     ></textarea>
//                   </li>
//                   <li>
//                     <input type="submit" className="flat-button" value="SEND" />
//                   </li>
//                 </ul>
//               </form>
//             </div>
//           </div>
//         </div>


//         {/* Snake Game section aligned after the contact form */}
//         <div className='right'>
//           <div className="game-section">
//             <SnakeGame />
//           </div>
//         </div>

//       </div>
//       <Loader type="pacman" />
//     </>
//   )
// }

// export default Contact


import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'
import Photo from './PhotoBrown'

const BrownFrame = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    const idTimeOut = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 4000)

    return () => clearTimeout(idTimeOut);
  }, [])

  return (
    <>
      <div className="container about-page">
        <div className="brownframe-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['BrownFrame',' ','Technologies']}
              idx={3}
            />
          </h1>
          <p align="LEFT">
          • Developed projects using the Waterfall methodology within the Software Development Life Cycle (SDLC), ensuring adherence to project timelines and requirements.
          </p>
          <p align="LEFT">
          • Configured deployment descriptors in Hibernate to establish efficient and effective object-relational mapping.
          </p>
          <p align="LEFT">
          • Built Micro Service Oriented applications, utilizing React.js for dynamic front-end experiences and Spring Boot services for
robust back-end functionality.
          </p>
          <p align="LEFT">
          • Employed Node.js to execute Webpack tasks seamlessly, ensuring proper project building and optimization.
          </p>
          <p align="LEFT">
          • Contributed to the development of engaging and responsive user interfaces (UI) using the Spring MVC framework, JSP, HTML,
CSS, JavaScript, jQuery, and AJAX.
          </p>
          <p align="LEFT">
          • Created multiple Proof of Concepts (POCs) to establish seamless communication between the backend and frontend, leveraging
MongoDB, Node.js, and React.js, proving the feasibility and effectiveness of proposed solutions.
          </p>
          <p align="LEFT">
          • Played a key role in migrating and managing existing on-premises applications to the cloud, utilizing various AWS services,
resulting in improved scalability and cost-efficiency.
          </p>
          <p align="LEFT">
          • Deployed apps on Tomcat with Jenkins CI, ensuring streamlined deployments.
          </p>
          <p align="LEFT">
          • Engaged with stakeholders, clarifying issues and tracking progress through Jira for communication and issue resolution.
          </p>
        </div>
        <Photo />
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default BrownFrame
import './App.scss';
import { BrowserRouter } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Home';
import About from './components/About'
import Contact from './components/Contact';
import Portfolio from './components/Portfolio';
import BrownFrame from './components/BrownFrame';
import Berkshire from './components/Berkshire';
import Eastbay from './components/Eastbay';
import Amotions from './components/Amotions';
import Peearz from './components/Peearz';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/about" index element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/brownframe" element={<BrownFrame />} />
          <Route path="/berkshire" element={<Berkshire />} />
          <Route path="/amotions" element={<Amotions />} />
          <Route path="/eastbay" element={<Eastbay />} />
          <Route path="/peearz" element={<Peearz />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;

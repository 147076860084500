import './index.scss'
import { useState } from 'react'
import LogoS from '../../assets/images/a-logo.png'
import LogoSubtitleFName from '../../assets/images/bg_ashwin.png'
import LogoSubtitleLName from '../../assets/images/bg_kinikar.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faGithub, faDiscord, faXTwitter} from '@fortawesome/free-brands-svg-icons'
import { faHome, faUser, faEnvelope, faSuitcase, faBars } from '@fortawesome/free-solid-svg-icons'
import { Link, NavLink } from 'react-router-dom'
// import Hamburger from 'hamburger-react'


const Sidebar = () => {
  const [showNav, setShowNav] = useState(false);

  return (
      <div className="nav-bar">
          <Link 
            className="logo"
            to="/"
            onClick={() => setShowNav(false)}>
            <img src={LogoS} alt="Logo" />
            <img className='sub-logo' src={LogoSubtitleFName} alt="ashwin" />
            <img className='sub-logo' src={LogoSubtitleLName} alt="kinikar" />
          </Link>
          <nav className={showNav ? 'mobile-show' : ''}>
            <NavLink 
              exact="true"
              activeclassname="active"
              to="/"
              onClick={() => setShowNav(false)}>
              <FontAwesomeIcon icon={faHome} color="#4d4d4e" />
            </NavLink>
            <NavLink 
              activeclassname="active"
              className="about-link"
              to="/about"
              onClick={() => setShowNav(false)}>
              <FontAwesomeIcon icon={faUser} color="#4d4d4e" />
            </NavLink>
            <NavLink
              activeclassname="active"
              className="portfolio-link"
              to="/portfolio"
              onClick={() => setShowNav(false)}
            >
              <FontAwesomeIcon icon={faSuitcase} color="#4d4d4e" />
            </NavLink>
            <NavLink
              activeclassname="active"
              className="contact-link"
              to="/contact"
              onClick={() => setShowNav(false)}
            >
              <FontAwesomeIcon icon={faEnvelope} color="#4d4d4e" />
              </NavLink>
              {/* <FontAwesomeIcon 
          onClick={() => setShowNav(false)}
          icon={faClose}
          color="#ffd700"
          size="3x"
          className='close-icon' /> */}
          </nav>
        <ul>
          <li>
            <a
              href="https://www.linkedin.com/in/ashwin-kinikar"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                icon={faLinkedin}
                color="#4d4d4e"
                className="anchor-icon"
              />
            </a>
          </li>
          <li>
            <a
              href="https://github.com/ashwinkinikar"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                icon={faGithub}
                color="#4d4d4e"
                className="anchor-icon"
              />
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/AshwinKinikar"
              rel="noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon
                icon={faXTwitter}
                color="#4d4d4e"
                className="anchor-icon"
              />
            </a>
          </li>
          <li>
            <a href="https://discord.com/users/756408148830584843" rel="noreferrer" target="_blank">
              <FontAwesomeIcon
                icon={faDiscord}
                color="#4d4d4e"
                className="anchor-icon"
              />
            </a>
          </li>
        </ul>
        <FontAwesomeIcon 
            onClick={() => setShowNav(true)}
            icon={faBars}
            color="#ffd700"r
            size="3x"
            className='hamburger-icon' />
      </div>
  )
}

export default Sidebar


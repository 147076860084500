import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Loader from 'react-loaders';
import AnimatedLetters from '../AnimatedLetters';
import Logotitle from '../../assets/images/a-logo.png';
import Photo from './Photo'
import './index.scss';

const Home = () => {
  const [letterClass, setLetterClass] = useState('text-animate')


  const nameArray = ['s', 'h', 'w', 'i', 'n', ' ', 'K', 'i', 'n', 'i', 'k', 'a', 'r']
  const jobArray = ['F', 'u', 'l', 'l', ' ', 'S', 't', 'a', 'c', 'k', ' ', 'D', 'e', 'v', 'e', 'l', 'o', 'p', 'e', 'r', ' ', '💻']

  useEffect(() => {
    const idTimeOut = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)

    return () => clearTimeout(idTimeOut);
  }, [])

  return (
    <>
      <div className="container home-page">
        <div className="text-zone">
          <h1>
            <span className={letterClass}>H</span>
            <span className={`${letterClass} _12`}>i,</span>
            <br />
            <span className={`${letterClass} _13`}>I</span>
            <span className={`${letterClass} _14`}>'m</span>
            <span className={`${letterClass} _15`}><img src={Logotitle} alt="developer" /></span>
            <AnimatedLetters letterClass={letterClass} strArray={nameArray} idx={16} />
            <br />
            <AnimatedLetters letterClass={letterClass} strArray={jobArray} idx={29} />
          </h1>
          <h2> Java / Python / JavaScript / ReactJs / NodeJs / AWS / Docker / Kubernetes / Jenkins </h2>
          <Link to="/contact" className="flat-button">CONTACT ME</Link>
          <Link to="https://drive.google.com/file/d/1P7QuZD6ildh_wV_kRNSANOV2tZUqpqjt/view?usp=drive_link"
            className="flat-button"
            target="_blank">Resume/CV</Link>
        </div>
        <Photo />
      </div>
      <Loader type="pacman" />
    </>
  );
}

export default Home
